/* Import Heebo font for modern Israeli typography */
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700&display=swap');

body {
  font-family: 'Heebo', 'Alef', sans-serif;
  margin: 0;
  background-color: #f7f9fc;
  color: #2c3e50;
  scroll-behavior: smooth;
  line-height: 1.6;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 80px;
}

.app-header {
  background: linear-gradient(135deg, #0038b8 0%, #005ce6 100%);
  color: white;
  width: 100%;
  text-align: center;
  padding: 25px 0;
  box-shadow: 0 3px 10px rgba(0, 56, 184, 0.15);
  margin-bottom: 30px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.app-header h1 {
  margin: 0;
  font-weight: 700;
  font-size: 2.2em;
  letter-spacing: 0.5px;
}

.about-link {
  position: absolute;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 8px 12px;
  text-decoration: none;
  opacity: 0.9;
  transition: opacity 0.2s ease;
  font-family: 'Heebo', sans-serif;
}

.about-link:hover {
  opacity: 1;
  text-decoration: underline;
}

.app-main {
  max-width: 800px;
  width: 100%;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
}

.input-section {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 35px;
  width: 100%;
}

.input-wrapper {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.input-helper-text {
  font-size: 0.85rem;
  color: #64748b;
  margin-left: 4px;
}

.input-field {
  width: 100%;
  padding: 12px 16px;
  font-size: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-family: 'Heebo', sans-serif;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.input-field:focus {
  outline: none;
  border-color: #0038b8;
  box-shadow: 0 0 0 3px rgba(0, 56, 184, 0.1);
}

.button-primary, .button-secondary {
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Heebo', sans-serif;
}

.button-primary {
  background-color: #0038b8;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 56, 184, 0.2);
  flex-shrink: 0;
  white-space: nowrap;
}

.button-primary:hover:not(:disabled) {
  background-color: #0046e5;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 56, 184, 0.25);
}

.button-primary:active:not(:disabled) {
  transform: translateY(0);
}

.button-secondary {
  background-color: #28a745;
  color: white;
  box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);
}

.button-secondary:hover:not(:disabled) {
  background-color: #2fb344;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(40, 167, 69, 0.25);
}

.button-secondary:disabled {
  background-color: #9ed5b0;
  cursor: not-allowed;
  box-shadow: none;
}

.perspective-section {
  background-color: #f8fafc;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.perspective-section h3 {
  margin: 0 0 15px 0;
  color: #0038b8;
  font-size: 1.2rem;
  font-weight: 500;
}

.radio-label {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  font-size: 1rem;
  cursor: pointer;
  color: #2c3e50;
}

.radio-label input[type="radio"] {
  margin-right: 6px;
}

.commentary-button-container {
  margin-top: 15px;
}

.button-helper-text {
  color: #64748b;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 6px;
}

.passage-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  margin-bottom: 30px;
}

.hebrew-text {
  direction: rtl;
  text-align: right;
  font-size: 1.3rem;
  color: #0038b8;
  margin: 15px 0;
  line-height: 1.8;
  font-family: 'David Libre', serif;
}

.english-text {
  font-size: 1.1rem;
  margin: 15px 0;
  line-height: 1.8;
  color: #2c3e50;
}

.error-message {
  color: #dc2626;
  font-weight: 500;
  margin-top: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
}

.commentary-section {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  margin-top: 30px;
  scroll-margin-top: 20px;
}

h2 {
  color: #0038b8;
  margin: 0 0 15px 0;
  font-size: 1.4rem;
  font-weight: 600;
}

.app-footer {
  text-align: center;
  padding: 15px 0;
  background: linear-gradient(135deg, #0038b8 0%, #005ce6 100%);
  color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -3px 10px rgba(0, 56, 184, 0.15);
  font-size: 0.9rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  color: #0038b8;
  margin-top: 0;
  margin-bottom: 20px;
}

.modal-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #2c3e50;
  margin: 0;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #f1f5f9;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s ease;
}

.modal-close:hover {
  background-color: #e2e8f0;
  color: #0038b8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-main {
    padding: 20px;
  }

  .input-section {
    flex-direction: column;
  }

  .input-wrapper {
    width: 100%;
  }

  .button-primary {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .hebrew-text, .english-text {
    font-size: 1rem;
  }

  .about-link {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}
